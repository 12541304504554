import { useKeenSlider } from 'keen-slider/react'
import 'keen-slider/keen-slider.min.css'

import {
  type SanitySliderItem,
  type SanitySliderModule,
  type SliderProductItem,
  type SliderVideoItem,
} from '@data/sanity/queries/types/modules'

import Product from './product'
import Video from './video'

type SliderProps = Pick<SanitySliderModule, 'type' | 'items'>

type SliderItemModuleProps = Pick<SanitySliderModule, 'type'> & {
  item: SanitySliderItem
  className?: string
}

const SliderItemModule = ({ type, item, className }: SliderItemModuleProps) => {
  switch (type) {
    case 'product': {
      return (
        <div className={className}>
          <Product
            {...(item as SliderProductItem)}
            imageSize={200}
            isInSlider
            className="h-full"
          />
        </div>
      )
    }

    case 'video': {
      return (
        <div className={className}>
          <Video {...(item as SliderVideoItem)} />
        </div>
      )
    }
  }
}

const Slider = ({ type, items }: SliderProps) => {
  const [sliderRef] = useKeenSlider<HTMLDivElement>({
    initial: 0,
    slides: {
      origin: 'auto',
      perView: type === 'product' ? 2.5 : 1.05,
      spacing: 10,
    },
  })

  return (
    <div
      ref={sliderRef}
      className="keen-slider"
      style={{
        overflow: 'visible',
      }}
    >
      {items?.map((item, index) => (
        <SliderItemModule
          key={`slide-${index}`}
          type={type}
          item={item}
          className="keen-slider__slide"
        />
      ))}
    </div>
  )
}

export default Slider
